import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Data, TiposDeErrores } from './snackbar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  tipos = TiposDeErrores;
  message: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: Data,
    public snackBarRef: MatSnackBarRef<SnackbarComponent>
  ) { }

  ngAfterViewInit(): void {
    let s = this.snackBarRef;
    window.onclick = function(event) {
      if(!document.getElementById("snackbar")?.contains(event.target)){
          s.dismiss();
      }
    }
  }

  ngOnInit(): void {
    if(this.data.error) {
      if (!this.data.error.Status) {
        this.data.error = JSON.parse(this.data.error.toString());
      }
      if (this.data.error.Status == this.tipos.Error) {
        this.message = "Ocurrió un error inesperado. Por favor comuníquese con el Administrador";
        document.documentElement.style.setProperty('--color', '#F64E60');
      } else if (this.data.error.Status == this.tipos.Advertencia) {
          document.documentElement.style.setProperty('--color', '#FFA800');
          this.message = this.data.error.Messages;
      } else if (this.data.error.Status == this.tipos.Informativo) {
          document.documentElement.style.setProperty('--color', '#8950FC');
          this.message = this.data.error.Messages;
      } 
    } else if (this.data.status == this.tipos.Permiso) {
      document.documentElement.style.setProperty('--color', '#F64E60');
      this.message = "No tiene permiso para realizar esta acción. Por favor comuníquese con el Administrador"
    }
    
  }

}
