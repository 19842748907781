import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  requestsPendientes = 0;

  constructor(public loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);
    this.requestsPendientes++;
    return next.handle(req).pipe(
      finalize(
        () => {
          this.requestsPendientes--;
          if (this.requestsPendientes == 0) {
            this.loaderService.isLoading.next(false)
          }
        }
      )
    )
  }
}
