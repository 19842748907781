import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthHTTPService } from './auth-http';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/pages/shared/services/project.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  // private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private authLocalStorageToken: string = 'usuario';
  private recoveryPasswordToken: string = '';

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private projectService: ProjectService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem(this.authLocalStorageToken)!));
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    // const subscr = this.getUserByToken().subscribe();
    // this.unsubscribe.push(subscr);
  }

  // public methods
  login(Username: string, Password: string, ConfirmPassword?: string, InvitationToken?: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(Username, Password, this.projectService.empresaId.value, ConfirmPassword, InvitationToken).pipe(
      map((user: UserModel) => {
        const result = this.setAuthFromLocalStorage(user);

        this.currentUserSubject.next(user);
        return result;
      }),
      // switchMap(() => this.getUserByToken()),
      catchError((err) => {
        return err.error.Messages;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.currentUserSubject.next(undefined);
    //localStorage.clear();
  }

  getUserByToken(): Observable<UserModel> {
    const user = this.getUserTokenFromLocalStorage();
    if (!user || !user.Token) {
      return of(undefined);
    }
    if (user) {
    } else {
      this.logout();
    }

    this.isLoadingSubject.next(true);
  }

  // // need create new user then login
  // registration(user: UserModel): Observable<any> {
  //   this.isLoadingSubject.next(true);
  //   return this.authHttpService.createUser(user).pipe(
  //     map(() => {
  //       this.isLoadingSubject.next(false);
  //     }),
  //     switchMap(() => this.login(user.email, user.password)),
  //     catchError((err) => {
  //       console.error('err', err);
  //       return of(undefined);
  //     }),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  //paso 1 recuperación de contraseña
  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .generarCodigoRecuperacion(email, this.projectService.empresaId.value)
      .pipe(
        map((resp: any) => {
          return true;
        }),
        catchError((err) => {
          return err.error.Messages;
        }),
        finalize(() => this.isLoadingSubject.next(false)));
  }

  //paso 2 recuperación de contraseña
  validarCodigoRecuperacion(email: string, recoveryCode: string): Observable<any>{
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .validarCodigoRecuperacion(email, recoveryCode, this.projectService.empresaId.value)
      .pipe(
        map((resp: any) => {
          this.recoveryPasswordToken = resp.token;
          return true;
        }),
        catchError((err) => {
          return err.error.Messages;
        }),
        finalize(() => this.isLoadingSubject.next(false)));
  }

  //paso 3 recuperación de contraseña
  restaurarPassword(email: string, password: string, confirmPassword: string, recoveryCode: string): Observable<any>{
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .restaurarPassword(email, password, confirmPassword, this.recoveryPasswordToken, recoveryCode, this.projectService.empresaId.value)
      .pipe(
        map((resp: any) => {
          return true;
        }),
        catchError((err) => {
          return err.error.Messages;
        }),
        finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(user: UserModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (user && user.Token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(user));
      return true;
    }
    return false;
  }

  private getUserTokenFromLocalStorage(): UserModel {
    try {
      const userData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return userData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
