import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { from, Observable, Subject } from 'rxjs';
import { CoreService } from '../../services/core.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DniArchivo, ItemDni } from './DniArchivo.interface';


@Component({
  selector: 'app-modal-dni',
  templateUrl: './modal-dni.component.html',
  styleUrls: ['./modal-dni.component.scss'],
  animations: [

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease', style({ transform: 'translateX(-100%)' }))
      ])
    ])

  ]
})
export class ModalDniComponent implements OnInit {
  @Input() data: DniArchivo;
  @Input() items: ItemDni[];
  @Input() documentoId: number
  fileToUpload = [];
  msjError: string = null;
  DocumentoFirmaPruebaDeVidaId: number[] = []
  public showWebcam = false;
  public btnText: string;
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  public currentPosition = 0;

  itemActual: ItemDni;
  imageSrc: string | ArrayBuffer;
  constructor(public activeModal: NgbActiveModal, private coreService: CoreService, private sanitization: DomSanitizer,) { }

  ngOnInit(): void {
    this.imageSrc = null
    this.btnText = "Siguiente"
    this.items = this.data.Archivos;
    this.itemActual = this.items[0]
    this.items.map((item, index) => {
      item.id = index;
    })
    this.currentPosition = 0
  }

  onFileDropped(e, id) {
    this.handleFileInput(e, id);
  }
  public triggerSnapshot(): void {
    this.trigger.next();
  }


  public toggleWebcam(): void {
    this.msjError = null;
    this.showWebcam = !this.showWebcam;
  }


  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }


  public handleImage(webcamImage: WebcamImage, id): void {
    this.webcamImage = webcamImage;
    const base64 = webcamImage.imageAsBase64
    const imageName = 'foto.jpeg';
    const imageBlob = this.dataURItoBlob(base64);
    let image = new File([imageBlob], imageName, { type: 'image/jpeg' });
    let archivo = { ArchivoSolicitadoId: id, Archivo: base64, NombreArchivo: imageName }
    this.fileToUpload.push(archivo)
    this.readFileToShow(image)
  }


  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }


  handleFileInput(files: FileList, id) {
    let fileName = files[0].name;
    let size = files[0].size;
    if (size > this.data.PesoMaximoArchivo) {
      this.msjError = `Solo imágenes hasta ${this.data.PesoMaximoArchivo / 1048576} MB`
    } else {
      let idxDot = fileName.lastIndexOf(".") + 1;
      let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        //TO DO
        this.msjError = null
        let image = files.item(0)
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
          this.imageSrc = reader.result;
          let archivo = { ArchivoSolicitadoId: id, Archivo: reader.result.toString().split(',')[1], NombreArchivo: fileName };
          this.fileToUpload.push(archivo)
        };
      } else {
        this.msjError = "Solo se aceptan archivos .jpg, .jpeg o .png !"
      }
    }
  }


  readFileToShow(img) {
    const file = img
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => { this.imageSrc = reader.result; };
  }


  setNext() {
    if (this.showWebcam) {
      this.showWebcam = false;
    }
    let nextPosition = this.currentPosition + 1;
    if (nextPosition === this.items.length - 1) {
      this.btnText = "Finalizar"
    }
    if (nextPosition > this.items.length - 1) {
      this.coreService.postArchivoRequerido(this.fileToUpload).subscribe(
        () => this.activeModal.close(true),
        () => this.activeModal.close(false)
      )
    } else {
      this.imageSrc = null;
      this.itemActual = null;
      this.currentPosition = nextPosition
    }
    setTimeout(() =>
      this.itemActual = this.items[this.currentPosition], 0)
  }

  deleteImg() {
    this.imageSrc = null;
    if (this.showWebcam) {
      this.showWebcam = false;
    }
    this.fileToUpload.pop()

  }
}