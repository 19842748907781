<div class="modal-container">
    <div class="modal-title border-bottom">
        <div class="d-flex justify-content-between">
            <h3 class="m-0">{{data.Titulo}}</h3>
            <i class="flaticon-close icon" (click)='activeModal.close(false)'></i>
        </div>
    </div>
    <div class="modal-contenido border-bottom">
        <div style="display: flex;width:100%;">
            <div class="slider-contenido">
                <div class="slider-item" [@slideInOut] *ngIf='itemActual'>

                    <div *ngIf='itemActual.Denominacion' style="width: 100%;">

                        <div class="slider-item-text">
                            <h3>{{itemActual.Denominacion}}</h3>
                        </div>
                        <div class="slider-item-uploadFile" appDnd
                            (fileDropped)='onFileDropped($event, itemActual.ArchivoSolicitadoId)'>
                            <div class="slider-item-uploadFile-border">
                                <div class="slider-item-uploadFile-text" *ngIf="!showWebcam && !imageSrc">
                                    Soltar archivos aquí, o importar desde
                                </div>
                                <div>
                                    <div *ngIf="showWebcam && !imageSrc"
                                        style="display: flex; flex-direction: column;align-items: center;position: relative;">
                                        <div (click)="toggleWebcam();"
                                            style="position: absolute; top: 0; left: 0; width: 30px; height: 30px; border-radius: 50%; display: flex; justify-content: center; align-items: center; background-color: black; z-index: 1; cursor: pointer;">
                                            <i class="flaticon2-back"></i>
                                        </div>
                                        <webcam [height]="250" [trigger]="triggerObservable"
                                            (imageCapture)="handleImage($event,itemActual.ArchivoSolicitadoId)"
                                            [imageQuality]="1" (initError)="handleInitError($event)"></webcam>
                                        <div class="btnFoto" (click)="triggerSnapshot();">
                                            <i class="flaticon-photo-camera" style="color: white;"></i>
                                        </div>
                                    </div>
                                    <div *ngIf="!showWebcam && !imageSrc"
                                        style="display: flex;justify-content: center;">
                                        <div
                                            style="display: flex;flex-direction: column; justify-content: center;align-items: center; margin-right: 16px;">
                                            <label for="file-upload" class="actionBtn"> <i class="flaticon-folder"
                                                    style="font-size: 24px;color: white;"></i></label>
                                            <input type="file" id="file-upload" accept="image/png,image/jpeg"
                                                (change)="handleFileInput($event.target.files, itemActual.ArchivoSolicitadoId)">
                                            <p>Mi dispositivo</p>
                                        </div>
                                        <div
                                            style="display: flex;flex-direction: column; justify-content: center;align-items: center;">
                                            <div class="actionBtn" (click)="toggleWebcam();"
                                                *ngIf="!showWebcam && !imageSrc"><i class="flaticon-photo-camera"
                                                    style="font-size: 24px; color: white;"></i>
                                            </div>
                                            <p>Camara</p>
                                        </div>
                                    </div>
                                    <div *ngIf='msjError'>
                                        <p class="text-danger">{{msjError}}</p>
                                    </div>
                                </div>
                                <div class="snapshot" *ngIf="imageSrc"
                                    style="position: relative;width: 100%; height: 100%; ">
                                    <ng-container *ngIf='imageSrc'>
                                        <div
                                            style="position: absolute;  background-color: red; right: 0;padding: 5px; border-radius: 0 0 0 10px;">
                                            <i class="flaticon2-delete"
                                                style="color: white; font-size: 18px; border-radius: 50%;"
                                                (click)='deleteImg()'></i>
                                        </div>
                                        <img [src]="imageSrc" class="img-upload" />
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="slider-steps" *ngIf='itemActual'>
            <div class="slider-steps-step slider-steps-step-{{currentPosition === item.id ? 'active' : '' }}"
                *ngFor='let item of items'>
            </div>
        </div>
    </div>
    <!--<div class="modal-actions">
        <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4 mr-4"
            (click)='activeModal.close(false)'>
            Cerrar
        </button>
        <button class="btn btn-primary font-weight-bold text-uppercase px-9 py-4" [disabled]='imageSrc === null'
            (click)='setNext()'>
            {{btnText}}
        </button>
    </div>-->
    <div class="modal-actions">
        <button mat-button color="primary" (click)='activeModal.close(false)'
                class="px-8 py-2 my-3 mr-3">
                Cerrar
        </button>
        <button mat-raised-button color="primary" [disabled]='imageSrc === null' (click)='setNext()'
                class="px-8 py-2 my-3 mr-3">
                {{btnText}}
        </button>
    </div>
</div>