import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';

const API_USERS_URL = `${environment.apiUrl}Auth/login`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  private _generarCodigoRecuperacionURL = `${environment.apiUrl}Auth/GenerarCodigoRecuperacion?email=`;
  private _validarCodigoRecuperacionURL = `${environment.apiUrl}Auth/ValidarCodigoRecuperacion?email=`;
  private _restaurarPasswordURL = `${environment.apiUrl}Auth/RestaurarPassword?codigo=`;
  private _pedirCambioPassword = `${environment.apiUrl}Auth/PedirCambioPassword?userName=`;
  constructor(private http: HttpClient) { }

  // public methods
  login(Username: string, Password: string, EmpresaId: number, ConfirmarPassword?: string, Token?: string): Observable<any> {
    return this.http.post<UserModel>(API_USERS_URL, { Username, Password, ConfirmarPassword, Token, EmpresaId });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  pedirCambioPassword(userName: string, empresaId) {
    return this.http.get<any>(`${this._pedirCambioPassword}${userName}&EmpresaId=${empresaId}`);;
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  generarCodigoRecuperacion(email: string, empresaId: number): Observable<any> {
    return this.http.post<any>(`${this._generarCodigoRecuperacionURL}${email}&empresaId=${empresaId}`, {});
  }

  validarCodigoRecuperacion(email: string, recoveryCode: string, empresaId: number) {
    return this.http.post<any>(`${this._validarCodigoRecuperacionURL}${email}&codigo=${recoveryCode}&empresaId=${empresaId}`, {});
  }

  restaurarPassword(username: string, password: string, confirmarPassword: string, token: string, recoveryCode: string, empresaId: number) {
    return this.http.post<any>(`${this._restaurarPasswordURL}${recoveryCode}`, { username, password, confirmarPassword, token, empresaId });
  }

  // getUserByToken(token): Observable<UserModel> {
  //   const httpHeaders = new HttpHeaders({
  //     Authorization: `Bearer ${token}`,
  //   });
  //   return this.http.get<UserModel>(`${API_USERS_URL}`, {
  //     headers: httpHeaders,
  //   });
  // }
}
