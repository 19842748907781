<div [ngStyle]="{'width': width}">
  <div>
    <mat-accordion>
      <mat-expansion-panel #card
      [expanded]="desplegado"
      (opened)="!desplegable && !desplegado && card.close()"
      (closed)="!desplegable && desplegado && card.open()"
      [hideToggle]="!desplegable"
      togglePosition="before">
        <mat-expansion-panel-header class="border">
          <mat-panel-title class="card-titulo"
          [ngStyle]="{'margin-top': marginTop, 'font-weight': tituloFontWeight, 'font-size': tituloFontSize}">
            {{titulo}}
          </mat-panel-title>
          <mat-panel-description [ngStyle]="{'margin-top': marginTop}">
            {{descripcion}}
          </mat-panel-description>
          <div>
            <ng-content select="[header]"></ng-content>
          </div>
        </mat-expansion-panel-header>
        <div class="body-content">
          <ng-content select="[body]"></ng-content>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
