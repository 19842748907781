import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { TerminosYCondicionesResponse } from '../_models/terminos-condiciones.model';
import { AuthService } from './auth.service';
import { TerminosCondicionesService } from './terminos-condiciones.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  terminosData: TerminosYCondicionesResponse;
  constructor(
    private router: Router, 
    private authService: AuthService,
    private terminosCondicionesService: TerminosCondicionesService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.terminosData = await this.terminosCondicionesService.GetCurrentUserInformation();
      if(this.terminosData.RequiereAceptarTerminosYCondiciones){
        this.authService.logout();
        this.router.navigate(['/auth']);
      }
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth']);
    return false;
  }
}
