import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export class ProjectStyles {
  LoginLogo:              any;
  HeaderLogo:             any;
  TextoBienvenida:        string;
}
 
@Injectable({
  providedIn: 'root',
})


export class ProjectService {
  private _getEstilosProyectoUrl = `${environment.apiUrl}Empresa/Estilos?EmpresaId=`;
  private _getEmpresaIdByUrl = `${environment.apiUrl}Empresa/GetIdEmpresaByUrl?url=`;

  public projectStyles = new BehaviorSubject<ProjectStyles>(null);
  public empresaId = new BehaviorSubject<number>(null);

  constructor(private _http: HttpClient) { 
  }

  public getEstilosProyecto(url: string) {
    this._http.get<any>(`${this._getEmpresaIdByUrl}${url}`).subscribe(empresaId => {
      
      this.empresaId.next(empresaId);

      this._http.get<any>(`${this._getEstilosProyectoUrl}${empresaId}`).subscribe(resp => {
        this.projectStyles.next({
          LoginLogo: resp.LoginLogo,
          HeaderLogo: resp.HeaderLogo,
          TextoBienvenida: "Bienvenido/a"
        });
        document.getElementById('titulo').innerHTML = resp.BrowserTabTitle;
        this.dataURItoBlob(resp.Logo.Contenido);
        document.documentElement.style.setProperty('--header-background', resp.HeaderBackground);
        document.documentElement.style.setProperty('--primary-color', resp.PrimaryColor);
        document.documentElement.style.setProperty('--primary-hover', resp.PrimaryHover);
        document.documentElement.style.setProperty('--primary-background', resp.PrimaryBackground);
        document.documentElement.style.setProperty('--primary-light-color', resp.PrimaryLightColor);
        document.documentElement.style.setProperty('--secondary-background', resp.SecondaryBackground);
      });
    }); 
  }

  dataURItoBlob(dataURI) {
    let byteString = window.atob(dataURI);
    let arrayBuffer = new ArrayBuffer(byteString.length);
    let int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([int8Array], { type: 'image/jpeg' });
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = e => {
      document.getElementById('logo-only').setAttribute("href", reader.result.toString());
    };
  }
}