import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ProjectService } from 'src/app/pages/shared/services/project.service';
import { SplashScreenService } from './splash-screen.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {

  @ViewChild('splashScreen', { static: true }) splashScreen: ElementRef;

  urlImg: any;

  constructor(
    private el: ElementRef,
    private splashScreenService: SplashScreenService,
    private projectService: ProjectService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.splashScreenService.init(this.splashScreen);
    this.projectService.projectStyles.subscribe(value => {
      if(value){
        const imageBlob = this.dataURItoBlob(value.HeaderLogo.Contenido);
        this.readFileToShow(imageBlob);
      }
      this.cdr.detectChanges()
    });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  readFileToShow(readFile) {
    const file = readFile
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => { this.urlImg = reader.result;};
  }

}
