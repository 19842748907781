import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(private _http: HttpClient) { }

  public getEstadoPruebaVida(documentoId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('documentoId', documentoId.toString());
    return this._http.get<any>(`${environment.apiUrl}PruebaDeVida/EstadoPruebaDeVida`, { params: params })
  }
  public postPruebaVida(FotografiaAutoretrato: string, FotografiaGestual: string, DocumentoFirmaPruebaDeVidaId1: number, DocumentoFirmaPruebaDeVidaId2: number): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}PruebaDeVida/UploadPruebaDeVida`, { FotografiaAutoretrato, FotografiaGestual, DocumentoFirmaPruebaDeVidaId1, DocumentoFirmaPruebaDeVidaId2 })
  }
  public postArchivoRequerido(archivos, esDocumento = false) {
    if (esDocumento) {
      return this._http.post<any>(`${environment.apiUrl}Documento/SubirArchivos`, { Archivos: archivos })
    } else {
      return this._http.post<any>(`${environment.apiUrl}ArchivoSolicitado/SubirArchivos`, { Archivos: archivos })
    }
  }
}
