// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v723demo1',
  // USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://preprodcontratosapi.rmg.com.ar/api/'

  //apiUrl: 'https://contratodevback.rmg.com.ar/api/'   --> DEV
  //apiUrl: 'http://192.168.71.253:8030/api/'           --> DEV

  // apiUrl: 'https://contratotestback.rmg.com.ar/api/'  --> TEST
  //apiUrl: 'http://192.168.71.253:8031/api/'           --> TEST
  //apiUrl: 'http://192.168.71.253:8041/api/'           --> FIRMIA 253

  //apiUrl: 'https://preprodcontratosapi.rmg.com.ar/api/'  --> PREPROD
  //apiUrl: 'http://192.168.71.253:8032/api/'              --> PREPROD

  //apiUrl: 'https://firmaonlineapi.locativa.com.ar/api/'  --> PROD
  //apiUrl: 'https://api.firmia.com.ar/api/'  --> PROD FIRMIA
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
