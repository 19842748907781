import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-desplegable',
  templateUrl: './card-desplegable.component.html',
  styleUrls: ['./card-desplegable.component.scss']
})
export class CardDesplegableComponent implements OnInit {
  @Input() titulo: string;
  @Input() tituloFontWeight = "bold";
  @Input() tituloFontSize = "110%";
  @Input() descripcion: string;
  @Input() desplegable = true;
  @Input() desplegado = false;
  @Input() width = "500px";
  @Input() marginTop = "0px";

  constructor() { }

  ngOnInit() {
  }
}
