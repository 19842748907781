import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pildora',
  templateUrl: './pildora.component.html',
  styleUrls: ['./pildora.component.scss']
})
export class PildoraComponent implements OnInit {
  @Input() text: string;
  @Input() tipo: string;
  @Input() tooltip?: string;
  constructor() { }

  ngOnInit(): void {
  }

}
