import { Component, Input, OnInit, ɵConsole } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { Archivo, InfoModalArchivos } from '../../models/InfoModalArchivos';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreService } from '../../services/core.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-modal-archivos',
  templateUrl: './modal-archivos.component.html',
  styleUrls: ['./modal-archivos.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease', style({ transform: 'translateX(-100%)' }))
      ])
    ])

  ]
})

export class ModalArchivosComponent implements OnInit {
  @Input() data: InfoModalArchivos;
  @Input() esDocumento = false;

  items: Archivo[];
  fileToUpload = [];
  msjError: string = null
  public showWebcam = false;
  public btnText: string;
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  public currentPosition = 0;
  private trigger: Subject<void> = new Subject<void>();
  itemActual: Archivo;
  fileSrc: string | ArrayBuffer;

  constructor(
    public activeModal: NgbActiveModal,
    private coreService: CoreService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.fileSrc = null
    this.items = this.data.Archivos;
    this.btnText = this.items.length <= 1 ? "Finalizar" : "Siguiente";
    this.itemActual = this.items[0]
    this.items.map((item, index) => {
      item.Index = index;
    })
    this.currentPosition = 0
  }

  onFileDropped(e, id) {
    this.handleFileInput(e, id);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage, id): void {
    this.webcamImage = webcamImage;
    const base64 = webcamImage.imageAsBase64
    const imageName = 'foto.jpeg';
    const imageBlob = this.dataURItoBlob(base64);
    let image = new File([imageBlob], imageName, { type: 'image/jpeg' });
    let archivo = { ArchivoSolicitadoId: id, Archivo: base64, NombreArchivo: imageName }
    this.fileToUpload.push(archivo)
    this.readFileToShow(image)
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  handleFileInput(files: FileList, id) {
    let fileName = files[0].name;
    let size = files[0].size;
    if (size > this.data.PesoMaximoArchivo) {
      this.msjError = `Solo archivos hasta ${this.data.PesoMaximoArchivo / 1048576} MB`
    } else {
      let idxDot = fileName.lastIndexOf(".") + 1;
      let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      let iconRoute = this.loadIconRoute(extFile);
      let archivoSolicitado = this.data.Archivos.find(file => file.ArchivoSolicitadoId == id);
      let extensionesValidas = archivoSolicitado.TiposArchivos;
      if (extensionesValidas.includes(extFile)) {
        this.msjError = null
        let file = files.item(0)
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          if (iconRoute != "default") {
            this.http.get(iconRoute, { responseType: 'blob' })
              .subscribe(res => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  var base64data = reader.result;
                  this.fileSrc = base64data
                }
                reader.readAsDataURL(res);
              });
          } else {
            this.fileSrc = reader.result;
          }
          let archivo = { ArchivoSolicitadoId: id, Archivo: reader.result.toString().split(',')[1], NombreArchivo: fileName };
          this.fileToUpload.push(archivo)
        };
      } else {
        this.msjError = `Solo se aceptan archivos ${extensionesValidas.filter(Boolean).join(", ")}.`;
      }
    }
  }

  loadIconRoute(fileExtension) {
    if (fileExtension == "pdf") {
      return './assets/media/logos/IconoPDF.png';
    } else if (fileExtension == "doc") {
      return './assets/media/logos/IconoDOC.png';
    } else if (fileExtension == "docx") {
      return './assets/media/logos/IconoDOCX.png';
    } else {
      return 'default';
    }
  }

  readFileToShow(readFile) {
    const file = readFile
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => { this.fileSrc = reader.result; };
  }

  setNext() {
    if (this.showWebcam) {
      this.showWebcam = false;
    }
    let nextPosition = this.currentPosition + 1;
    if (nextPosition === this.items.length - 1) {
      this.btnText = "Finalizar"
    }
    if (nextPosition > this.items.length - 1) {
      this.coreService.postArchivoRequerido(this.fileToUpload, this.esDocumento).subscribe(
        () => this.activeModal.close(true),
        () => this.activeModal.close(false)
      )
    } else {
      this.fileSrc = null;
      this.itemActual = this.items[nextPosition];
      this.currentPosition = nextPosition
    }
    setTimeout(() =>
      this.itemActual = this.items[this.currentPosition], 0)
  }

  deleteImg() {
    this.fileSrc = null;
    if (this.showWebcam) {
      this.showWebcam = false;
    }
    this.fileToUpload.pop()
  }
}
