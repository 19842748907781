export interface Data {
    error:      Error;
    status:     number;
}

export interface Error {
    Messages:    string;
    Status:     number;
}
  
export const TiposDeErrores = {
    Error: 1,
    Informativo: 2,
    Advertencia: 3,
    Permiso: 403
}