<div class="d-flex align-items-center justify-content-between" id="snackbar">
    <i class="flaticon-cancel" *ngIf="data.error?.Status == tipos.Error || data.status == tipos.Permiso"></i>
    <i class="flaticon-exclamation-1" *ngIf="data.error?.Status == tipos.Informativo"></i>
    <i class="flaticon-warning" *ngIf="data.error?.Status == tipos.Advertencia"></i>
    <p [innerHTML]="message"></p>
    <div class="dismiss">
        <button mat-icon-button (click)="snackBarRef.dismiss()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>