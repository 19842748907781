import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { AuthService } from '../_services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/pages/shared/components/snackbar/snackbar.component';
import { ProjectService } from 'src/app/pages/shared/services/project.service';

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptorService implements HttpInterceptor {

  private _locativaAppAuthorization = "hfT46KfE46-kD83HI8DDn-nsU8B6V5vv-lJbdf86uHJ";
  
  constructor(
    private _authService: AuthService,
    private _snackbar: MatSnackBar
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const usuario = this._authService.currentUserValue;
    if (usuario) {
      req = req.clone
        (
          {
            setHeaders:
            {
              Authorization: `Bearer ${usuario.Token}`
            }
          }
        )
    }
    req = req.clone
      (
        {
          setHeaders:
          {
            AppAuthorization: this._locativaAppAuthorization
          }
        }
      )
    return next.handle(req).pipe(catchError((response: HttpErrorResponse) => {
      if (response.status === 401 ||
          (response.status === 403 && response.url.includes('TerminosYCondiciones/Obtener'))) {
        this._authService.logout();
        document.location.reload();
      }
      else if (response.status != 403 || !response.url.includes('Tramite/TramitesPorUsuario')) {
        this.showSnackBar(response);
      }
      return throwError(response);
    }));
  };

  showSnackBar(error): void {
    this._snackbar.openFromComponent(SnackbarComponent, {
      data: error,
      horizontalPosition: 'center',
      duration: 0
    });
  }
}
