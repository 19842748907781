import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TerminosYCondicionesResponse } from '../_models/terminos-condiciones.model';

@Injectable({
  providedIn: 'root'
})
export class TerminosCondicionesService {
  private _obtenerTerminosYCondiciones = `${environment.apiUrl}TerminosYCondiciones/Obtener`;
  private _aceptarTerminosYCondiciones = `${environment.apiUrl}TerminosYCondiciones/Aceptar?Id=`;
  
  constructor(private http: HttpClient) { 
  }

  obtenerTerminos(){
    return this.http.get<TerminosYCondicionesResponse>(this._obtenerTerminosYCondiciones, {});
  }

  aceptarTerminos(id: number){
    return this.http.post<any>(`${this._aceptarTerminosYCondiciones}${id}`, {});
  }

  public GetCurrentUserInformation(): Promise<TerminosYCondicionesResponse>{
    return this.obtenerTerminos().toPromise()
}
}
